]<!-- =========================================================================================
    File Name: Faq.vue
    Description: FAQ Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="faq-page">
        <!-- JUMBOTRON -->
        <div class="faq-jumbotron">
            <div class="faq-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Hizmetlerimiz</h1>
                <aside class="mb-1 text-white">
                    <h4 class="mb-1 text-white">Oluşum Bilgi İşlem San. ve Tic. Ltd. Şti</h4>
                    <span> Bilişim sektörüne 2000 yılında Teknolojide Güven Sloganıyla adım atan  Oluşum Bilgi İşlem Paz.San.Tic.Ltd.Şti. son teknolojiyi, rekabetçi fiyatla ve eksiksiz destek hizmetiyle Türk BT pazarına sunmak amacıyla kurulmuştur. Hem tedarikçi firmalara hem de kurumsal ve bireysel müşterilerine yalın ve direkt süreçlerle ulaşarak, sürekli maliyet avantajı oluşturmayı hedeflemektedir. Böylelikle, kaliteden ödün vermeksizin, en yeni teknolojiyi yansıtan ürün ve çözümlerini, en uygun fiyatla pazara sunabilmektedir. En zor projelerde bile müşterilerinin yanında olan Oluşum Bilgi İşlem Paz.San.Tic.Ltd.Şti. , sistem entegrasyon ve danışmanlık hizmetleri ile teknolojide güven sloganı çerçevesinde eksiksiz, tam çözüm sağlamaktadır.</span>
                </aside>
                <vs-input placeholder="Search" v-model="faqSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full mt-6" icon-no-border />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg">
                <vx-card>
                    <h4>Hizmetler</h4>
                    
                    <ul class="faq-topics mt-4">
                        <li v-for="category in categories" :key="category.id" class="p-2 font-medium flex items-center" @click="faqFilter = category.id">
                            <div class="h-3 w-3 rounded-full mr-2" :class="'bg-' + category.color"></div><span class="cursor-pointer">{{ category.name }}</span>
                        </li>
                    </ul>

                    <br><br>

                    
                    
                </vx-card>
            </div>

            <!-- FAQ COL -->
            <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
                <vs-collapse accordion type="margin" class="p-0">
                    <vs-collapse-item v-for="(que,index) in filteredFaq" class="faq-bg rounded-lg" :class="{'mt-0': !index}" :key="que.id">
                        <div slot="header"><h5>{{ que.question }}</h5></div>
                        <p v-html="que.ans"></p>
                    </vs-collapse-item>
                </vs-collapse>
                
            </div>
        </div>
    </div>
</template>

<script>

export default{
    data() {
        return {
            faqSearchQuery: '',
            faqFilter: 1,
            categories: [
                {
                    id: 1,
                    name: 'Tümü',
                    color: 'grey',
                },
                {
                    id: 2,
                    name: 'Yerinde Servis',
                    color: 'primary'
                },
                
                {
                    id: 4,
                    name: 'Notebook Tamir',
                    color: 'success'
                },
                {
                    id: 5,
                    name: 'Network Yönetim',
                    color: 'danger'
                },
                {
                    id: 6,
                    name: 'Bakım Anlaşması',
                    color: 'danger'
                },
                {
                    id: 7,
                    name: 'Data Kurtarma',
                    color: 'danger'
                },
                {
                    id: 8,
                    name: 'Web Tasarım',
                    color: 'warning'
                },
                {
                    id: 9,
                    name: 'Hosting',
                    color: 'primary'
                },
            ],
            faqs: [
                {
                    id: 1,
                    categoryId: 2,
                    question: 'Yerinde Servis',
                    ans: "<h3>Yerinde Servis Nedir ?</h3> \
                                    <p>Eğer bilgisayarınız varsa, siz de en az bir kere bilgisayarınızın kasasını şartlanıp teknik servisin yolunu tutmuşsunuzdur ve bunun ne kadar zor bir iş olduğunun bilincindesinizdir. İşte Yerinde Servis tamda bu probleme tepki olarak doğmuştur!</p> \
                                    <p>Yerinde Servis, alıştığınız klasik teknik servis mantığının ötesinde, arızaya yerinde müdahale etmeyi esas alan bir sistemdir. Kısacası artık bilgisayarınızı sartlanıp yollara düşmenize gerek kalmadı, biz size geliyoruz.</p> \
                                    <p>Ekonomik, etkili ve hızlı bir şekilde arızalı cihazlarınızı ya yerinde onarıyoruz ya da gerekirse servis merkezimize götürüp onardıktan sonra evinize kadar teslim ediyoruz. Üstelik sandığınız gibi yüksek fiyatlara değil! Fiyat tarifelerimiz için tıklayınız..</p> \
                                    <p>Kısacası, bilgisayar sorunlarınız için ekonomik ve etkili bir çözüm olan Yerinde Servis bir telefon uzağınızda. 0212 210 7046 nolu danışma hattımızdan her türlü bilgiye ulaşabilir, Yerinde Servis talebinde bulunabilirsiniz.</p> \
                                    <h3>Yerinde Servis İşleyiş</h3> \
                                    <p>Aslına bakılırsa Yerinde Servis in çalışma prensibi çok basittir. Normal şartlarda kendi imkânlarınızla getirdiğiniz bilgisayarınıza, gelip yerinde müdahale ediyoruz ve gereken durumlarda alıp servis merkezimizde onardıktan sonra tekrar evinize kadar teslim ediyoruz.</p> \
                                    <p>0212 210 7046 nolu  danışma hattımızdan bize ulaşan müşterimiz için önce bir arıza kaydı açılır ve telefonda bir ön teşhis yapılır. Bu ön teşhis neticesinde müşterinin de arzusu ile bir randevu saati belirlenir.</p> \
                                    <p>Belirlenen gün ve saatte müşterinin evinde olan teknisyen, arızaya yerinde müdahale eder. Arızanın yerinde giderilemeyeceği durumlarda bilgisayarı alıp servis merkezine götürür.</p> \
                                    <p>Servis merkezinde gerekli işlemleri yapılan bilgisayar yine önceden randevulaşmak suretiyle müşterinin işyerine/evine kadar götürülüp, kurulumu ve testleri bizzat teknisyen tarafından yapılarak sorunsuz bir şekilde teslim edilir.</p> \
                                    <p>Bu sayede sadece bir telefonla Yerinde Servis hizmetine ulaşan müşterimiz, arızasını en hızlı, etkili ve ekonomik yolla çözmenin keyfini sürer...</p> \
                                    <h3>Neden Yerinde Servis?</h3> \
                                    <li>Yerinde Servis hizmetini neden tercih etmeniz gerektiğinin cevabı aslında çok açık, çünkü bu adı üzerinde Yerinde Servis. Pek çok açıdan bizi tercih etmeniz sizin faydanızadır.</li> \
                                    <li>Yerinde Servis Ekonomiktir, Ek ücret talep etmez!</li> \
                                    <li>Bilgisayarınızı yüklenip bir servise götürüyorsunuz, yol parası veriyorsunuz. Bu esnada servisin sizden ne kadar ücret talep edeceğini ve arızanızın ne kadar sürede onarılacağını bilmiyorsunuz. Diyelim ki aynı gün içerisinde arızanız giderilemedi, mecburen eve dönüyorsunuz. Daha sonra bilgisayarınızı almak için tekrar yollara düşüyorsunuz. Servis ücretini ödeyip tekrar eve dönüyorsunuz. Bu süreçte dört kez yol parası veriyor ve üzerine zamandan da kaybediyorsunuz. Bu anlamda, hem servis ücreti hem de sizleri yol masrafından kurtarması dolayısıyla Yerinde Servis en ekonomik çözümdür. Yani Yerinde Servis yol masrafı için ek ücret talep etmez ve servis ücreti son derece makuldür.</li> \
                                    <li>Yerinde Servis Hızlıdır, Aynı gün işiniz çözülür!</li> \
                                    <li>Geleneksel servis hizmetinin aksine, yerinde servis hızlıdır. Parça nedenli gecikmeler dışında, bilgisayarınız en geç 24 saat içinde evinize teslim edilir. Üstelik arıza bildirimlerine aynı gün müdahale edilir. Siz de zaman benim için kıymetli diyorsanız, Yerinde Servis tam size göre...</li> \
                                    <h3>Sık Sorulan Sorular</h3> \
                                    <li><strong>Bilgisayarımı servise götürmek yerine, Yerinde Servis hizmetinden yararlanmam bana ek bir masraf çıkarır mı?</strong></li> \
                                    <p>Sandığınızın aksine Yerinde Servis sizden yüksek ücretler talep etmez, hatta pek çok geleneksel servisten daha ekonomiktir. Ayrıntılı ücret tarifesi için lütfen tıklayınız...</p> \
                                    <li><strong>Evimden alınan bilgisayarımın, tekrardan eve getirilip kurulumunun yapılması ek ücrete mi tabidir?</strong></li> \
                                    <p>Hayır, Yerinde Servis'in sizden talep ettiği ücret, evden alınmasını, onarımını ve tekrardan eve bırakılıp kurulmasını kapsar.</p> \
                                    <li><strong>Yerinde Servis hizmeti ne kadar hızlıdır? Yani alınan bilgisayarım ne zaman geri getirilir?</strong></li> \
                                    <p>Normal şartlarda, yani parça arızasına bağlı gecikme halleri dışında, Yerinde Servis aynı gün içerisinde evinize gelir ve alınan bilgisayarı en geç 24 saat içerisinde size teslim eder.</p>" 
                },
                {
                    id: 2,
                    categoryId: 4,
                    question: 'Notebook Tamiri',
                    ans: "<p>Markası,  modeli ve sorunu ne olursa olsun arızalı notebook’larınızı adresinizden alıyor, adresinize teslim ediyoruz.</p> \
                                    <h3>Müşterilerimize Verdiğimiz Hizmetlerimiz:</h3>  \
                                    <li>Notebook Lcd ekran değişimi</li>  \
                                    <li>Notebook klavye değişimi</li> \
                                    <li>Notebook anakart değişimi ve tamiri</li> \
                                    <li>Notebook optik okuyucularının temizlenmesi ve değişimi,</li> \
                                    <li>Darbelerden ve yıpranmadan kaynaklanan notebook  kasa, menteşe ve butonların değişimi ve tamiri</li> \
                                    <li>Notebook harddisk değişimi</li> \
                                    <li>Notebook batarya hücrelerinin yenilenmesi ve tüm Notebook Arızalarınızın giderilmesi firma politikası olarak benimsediğimiz hızlı çözüm garantisi ve  uygun fiyat ile müşterilerimize sunulmaktadır.</li> \
                                    <br> \
                                    <p>Ücretsiz ve Hızlı Arıza Tespiti</p> \
                                    <p>Fiyat Onayı Alınmadan işlem Yapılmama Garantisi</p> \
                                    <p>Onay alındıktan sonra max. 48 saat içinde onarım işlemi</p> \
                                    "
                },
                {
                    id: 3,
                    categoryId: 5,
                    question: 'Network Yönetim',
                    ans: 'Uzak bağlantı VPN, Cisco Systems, Uzak Masaüstü Uygulamaları Yapılandırılması'
                },
                {
                    id: 4,
                    categoryId: 6,
                    question: 'Bakım Anlaşması',
                    ans: '<h3>Bakım Anlaşmaları</h3> \
                                    <p>Ticari işletmeler için, bilgisayarlardan kaynaklanan en ufak bir sorun hem personelin vakit kaybına, hem ticari zararlara sebebiyet vermekte, o zaman sorun meydana geldikten sonra çözüm bulmaya değil, sorunun meydana gelmesini engellemeye çalışmak gerekmekte...</p> \
                                    <h3>Kurumsal Bakım Anlaşması Nedir?</h3> \
                                    <p>Kurumsal Bakım Anlaşması, yapılan anlaşmaya göre, günün her saatinde veya mesai saatleri içerisinde, Bilgisayar Donanımı, içinde bulunan yazılımlar veya Bilgisayar Çevre Birimleri ile ilgili herhangi bir sorun meydana geldiğinde veya herhangi bir sorun meydana gelmemesi için, bilgisayarlara müdahale edilip, sorunların giderilmesi, gereken önlemlerin alınması hizmetlerini kapsar.</p> \
                                    <h3>Kimler Kurumsal Bakım Anlaşması Yaptırmalıdır?</h3> \
                                    <p>İşletmesinde bulunan bilgisayar, yazıcı, tarayıcı, barkod üniteleri, plotter vb. çevre birimlerinin sayısı beş ve üzerinde olan, bilgisayarlarında sorun meydana geldiğinde, acil çözüm gereken, tüm işletmeler Kurumsal Bakım Anlaşması yaptırabilirler.</p> \
                                    <h3>Kurumsal Bakım Anlaşması Ne Tür Hizmetleri Kapsar?</h3> \
                                    <p>Kurumsal Bakım anlaşması süresince Oluşum Bilgi İşlem Teknik Servisinde veya müşteri yerinde limitsiz servis desteği, ayda bir kez periyodik koruyucu bakım, envanter takibi, arızalı cihazın müşteri yerinde onarılamadığı durumlarda yerine yedek cihaz bırakılması, Novell, Windows, Linux çoklu işletim sistemi, işletim sistemi desteği, bilgisayar, notebook, yazıcı ve diğer çevre birimleri onarımı, MS Office uygulamaları ve antivirus desteği, garanti hizmetleri, orjinal yedek parça desteği,  Ticari ve Sektörel Programların Kurulum, Eğitim ve Desteklerinin verilmesi, standart hizmetlerimizden olup gerektiği durumlarda, help destek ve benzeri hizmetler eklenebilir. Gerek olmayan hizmetler anlaşma kapsamından çıkarılabilir.</p> \
                                    <h3>Kurumsal Bakım Anlaşmasının Maliyeti Nedir?</h3> \
                                    <p>Kurumsal Bakım Anlaşması maliyeti müşteri, personeli ve işletme bilgisayarlarının kullanım amaçlarına göre, değişmekte olup, aylık maliyeti 150 TL ve üzeridir.</p> \
                                    <h3>Kurumsal Bakım Anlaşmasının Geçerlilik Süresi Nedir?</h3> \
                                    <p>Kurumsal Bakım Anlaşması 1 yıllık süreler haline yapılmaktadır.</p> \
                                    <h3>Kurumsal Bakım Anlaşması Yaptırmanın Avantajı Nedir?</h3> \
                                    <p>Kurumsal Bakım Anlaşmalı müşterilerimiz en öncelikli müşterilerimizdir. Telefon, Messenger Uzak Masaüstü Bağlantısı ve Yerinde Servis hizmetlerimizden, sorunu en hızlı çözebilecek olan yöntem tercih edilir ve müşteri sorunları giderilir. Müşterilerimiz, donanımları bozulduğu için iş kaybına uğramazlar, Verilerinin yedekleri her zaman alınır, Teknolojik gelişmelerden anında haberdar olurlar, ek hiçbir ücret ödemezler, diğer donanım ürünlerini aldıklarında çok özel ödeme koşulları ve iskontolar sağlanır.</p> \
                                    <h3>Kurumsal Bakım Anlaşmasına Nasıl Başlanır?</h3> \
                                    <p>Öncelikle bir ön görüşme ile talepler dinlenir, sonrasında müşteri temsilcilerimiz, teknisyenlerimizle beraber, işletmeyi analiz eder ve yapılabilecek hizmetler hakkında bir brief verirler. Takip eden süreçte bir sözleşme hazırlanır, onaylanması durumunda, firmanın büyüklüğüne göre 2-5 kişilik bir ekip, işletme bilgisayarlarında toplu bir çalışma gerçekleştirir, eksikler belirlenir, yedekler alınır, ilk bakım yapılır, firmanın teknik yapısı, eksikler, geliştirmeler hakkında bir rapor hazırlanarak şirket yönetimine sunulur, bu tarihten itibaren de her türlü sorunda hızlı ve yerinde destek sağlanır.</p>' 
                },
                {
                    id: 5,
                    categoryId: 7,
                    question: 'Data Kurtarma',
                    ans: '<h3>Bilgi kaybı temel niteliğine göre, data kurtarma işlemini aşağıdaki gibi sınıflandırıyoruz:</h3> \
                                    <li>Dosya sistemi (FAT, FAT16, FAT32, NTFS, HPFS, ...) bozulmasından dolayı kaybolan verilerin kurtarılması</li> \
                                    <li>Disk kartı bozulmasından dolayı oluşan data kaybında veri kurtarılması</li> \
                                    <li>Fiziksel arızalı (Okuyucu kafa sıkışması, bozulması, kirlenmesi) veri depolama birimlerinden veri kurtarılması</li> \
                                    <li>Bozulan RAID dizilerinden data kurtarılması (raid0, raid1, raid2, raid5, ...)</li> \
                                    <li>USB ve taşınabilir disklerden bilgi kurtarılması (Harici USB diskler, Flash bellekler, ...)</li> \
                                    <li>CD/DVD gibi optik bilgi depolama birimlerinden bilgi kurtarılması</li> \
                                    <li>Bellek kartlarından veri kurtarılması (CF, MMC, Memory Stick, ...)</li> \
                                    <li>1,8" disklerden bilgi kurtarılması</li> \
                                    <h3>Veri kurtarma işlemi aşağıdaki aşamalardan oluşmaktadır:</h3> \
                                    <li>Diskin analizi ve raporlanması.</li> \
                                    <p>Disk elimize ulaştığı andan itibaren diskteki bilgilerin kurtarılabilirliği, arıza tesbiti, arıza nedeni, onarılabilme durumu gibi konuları kapsayan disk analizi işlemi başlatılır. Analiz ve raporlama işlemi disk durumu ve yapılacak işlemlere göre 1 saat ile 24 saat arasında değişir.</p> \
                                    <li>Ürün aynı zamanda bilgi bankamıza müşteriye verilen form numarası ile birlikte kaydedilip, işlemleri online takip edebilmesi için müşteriye şifre verilmektedir.</li> \
                                    <li>Analiz işlemi bittikten sonra alınan rapora göre datanın kurtarılabilme olasılığı, kullanılacak teknoloji, alınabilecek dosya ve klasörler, alınabilme yüzdesi, yaklaşık işlemin süreceği zaman birimi, işlem maliyeti gibi ayrıntılı rapor müşteriye sunulur. Raporlar müşteriye fax, e-mail ve online takip sistemimizden iletilmektedir.</li> \
                                    <p><strong>Not: Rapor süresi, yaklaşık sürecek zaman miktarı diskteki hasar durumuna göre değişebilir.</strong></p> \
                                    <li>Verilen raporlar doğrultusunda müşteriden yazılı onay alındıktan sonra data kurtarma işlemine başlanır.</li> \
                                    <li>Data kurtarma süresince sürekli müşteriye online takip sisteminden bilgi, rapor verilmektedir.</li> \
                                    <li>Kurtarma işlemi bittikten sonra kurtarılan verilerin onaylanması için müşteriye haber verilir. Müşteri, bilgilerin güvenliği açısından, datalar bizzat kendisi yada vekalet ettiği kişi tarafından kontrol edilir ve onaylandıktan sonra datalar müşteriye teslim edilir.</li> \
                                    <li>Müşteri verileri aldıktan sonra en fazla 1 hafta içerisinde tarafımıza elimizde olan bilgilerin silinmesi konusunda onay ve talimat verir. Aksi olmadığı müddetçe müşteriye ait olan verilerin yedeği 1 hafta sonra güvenli bir şekilde tamamiyle sistemimizden silinecektir.</li>' 
                },
                {
                    id: 6,
                    categoryId: 8,
                    question: 'Web Tasarım',
                    ans: '<p>Web sitesi, günümüzdeki en etkili ve düşük maliyetli tanıtım aracıdır. Tanıtım ise bir firmanın sürekliliğinde ve başarılı  olmasında  en  önemli etkenlerden  biridir. Tanıtılamayan bir hizmet yada bir ürünün  tüketicisine ulaştırılamayacağı ve aynı şekilde pazarlanamayacağı herkes tarafından bilinmektedir.</p> \
                                    <p>Sizde daha fazla vakit kaybetmeden gelin günümüzün en etkili tanıtım ve iletişim aracı olan Dijital Dünya da yerinizi alın.</p> \
                                    <ul><h3>Web Sitesi ve Web Tasarım Nasıl Olmalı?</h3> \
                                    <li>Web Tasarım kurumsal kimlik ile uyumlu olmalı.</li> \
                                    <li>Web Tasarım hızlı olmalı.</li> \
                                    <li>Web Tasarım özgün olmalı.</li> \
                                    <li>Web Tasarım etkileşimli olmalı.</li> \
                                    <li>Web Tasarım bilgi vermeli.</li> \
                                    <li>Web Tasarım iletişim kurmalı.</li> \
                                    <li>Web Tasarım güncel olmalı.</li> \
                                    </ul>  \
                                    <h3>E-ticaret</h3> \
                                    <p>Yeni Nesil E-ticaret sistemi ile ürünlerinizi internet üzerinden tüm Türkiye pazarına sunup, tanıtım ve satışını yapabileceğiniz daha fazla müşteriye daha ekonomik şekilde ulaşabileceğiniz profesyonel olarak programlanmış bir elektronik ticaret sistemiyle tanışmak istiyorsanız lütfen bizi arayın.</p> \
                                    <h3>Web Yazılım</h3> \
                                    <p>Online web içerik yönetim sistemleri, oluşturulan web sitenizi size özel yönetim paneli kullanarak kolaylıkla yönetilebilmenizi sağlamaktadır. Site içerikleri zaman içersinde anlamlarını yitirmekte ve pek çok site gündemin gerisinde kalabilmektedir. Bu ihtiyaç doğrultusunda ortaya çıkan Web İçerik Yönetimi Sistemleri ile site içeriginin kontrollü ve ilgili birim/departman tarafından, herhangi teknik bilgiye ihtiyaç duymadan, site konseptine ve devamlılığına zarar vermeden güncellenmesi sağlanmaktadır. Sitenizin sürekli güncel olmasi işinizdeki verimi mutlaka yükseltecektir. Yazılım geliştirme çalışmalarımız web tabanli sistemler üzerine odaklıdır. Web servisleri üzerinde yoğunlastıgımız için geliştirme platformu olarak Linux u kullanmaktayiz. Fakat özellikle veritabanı sistemlerinde MYSQL ile de çalışmalarımız olmaktadır. Teknoloji olarak Apache Sunucuları ve PHP ağırlıklı olarak çalışıyor olsak da .NET altyapısını da projelerimize uygulamaktayız. Bizler iş dünyasında kullanılan en güncel sistemler ve donanimlardaki deneyimleriyle sizlere doğru sistemi seçmenizde ve gerçeklestirmenizde yardımcı olmaktayız..</p> \
                                    <h3>Google ve Arama Motoru Optimizasyonu</h3> \
                                    <p>Google bu konuda şöyle diyor :</p> \
                                    <p><strong>- Kimse Google da 1 numara olmayı garantileyemez.</strong></p> \
                                    <p>Biz de aynı şeyi söylüyoruz. Bununla birlikte çalıştığımız çok sayıda örnekte sağladığımız başarılar bu işin mucize olmadığını da gösteriyor. Sitenizi arama motorlarına uyumlu hale getirmek istiyorsanız, doğru adrestesiniz demektir.</p>  \
                                    <h3>Google Adwords</h3> \
                                    <p>Arama sonuçlarının dışında ürünlerinizin hedef kitlesine yönelik web siteleri bulunarak site hedefli (site targeting) özel kampanyalar düzenlenir. Niş kitlelere kolayca ulaıılır, düşük maliyetle yüksek geri dönüş oranları elde edilir.</p> \
                                    <p>Google Adwords Reklamlarının Tıklama Başı Maliyet ücretlendirmesi sayesinde esnek reklam bütçeleri oluşturmanızı sağlar.</p> \
                                    <p>Reklamlarınızı Global veya Ülke bazında Google raklamlarını kullanabileceğiniz gibi, dilerseniz, sadece seçtiğiniz dilleri kullanan ziyaretçilere gösterebilirsiniz.</p> \
                                    <p>İstediğiniz gün veya saatlerde reklamlarını açabilir veya kapayabilirsiniz.</p> \
                                    <p>Geri dönüşüm oranları çok yüksek olan Adwords reklamlarınız hakkında detaylı raporlar alabilirsiniz. Bir e-ticaret siteniz varsa, hangi kelimeden gelen kullanıcıların daha çok satışa dönüştüğünü analiz edebilir ya da e-posta haber listenize üye olup olmadıklarını takip edebilirsiniz.</p> \
                                    <p>Çıkaracağınız analizler sayesinde gerekirse reklamlarınızda iyileştirmelere gidebilir, verimsiz anahtar kelimeleri çıkarabilir, yerlerine yenilerini ekleyebilirsiniz.Hedef kitlenizi iyice büyütmek isterseniz uzman ekibimiz ile reklamlarınızın gösterilmesini sağlayabilirsiniz.</p> '
                },
                {
                    id: 7,
                    categoryId: 9,
                    question: 'Hosting',
                    ans: '<p>Web hosting hizmetlerimiz , siz müşterilerimizin ihtiyaçlarına göre hazırlanmıştır . Windows Server platformundan Linux tabanlı sistemlere kadar geniş bir yelpazeye ayrılan hizmetlerimiz hakkında bilgileri aşağıdaki linkleri takip ederek alabilirsiniz.</p> \
                                    <h3>PHP , Mysql Linux Hosting Seçenekleri</h3> \
                                    <p>Centos ve FreeBSD sunucularımızda php kullanmanız için uygun paketlerdir. Veritabanı olarak mysql kullanabilirsiniz. Ayrıca sunucumuzda gd , curl , freetype ve zend bileşenlerini de kullanabilirsiniz.</p> \
                                    <h3>ASP , ASP.NET Windows Hosting Seçenekleri</h3> \
                                    <p>Windows 2003 Web Edition üzerinde ASP ve ASP.NET uygulamalarını çalıştırmanız için uygun olan platformdur. Ayrıca MSSQL eklentisi ile paketinizde mssql kullanma hakkına sahip olabilirsiniz.</p> \
                                    <h3>Kurumsal Hosting Paketleri</h3> \
                                    <p>Kurumsal hosting paketlerinde PHP , ASP , ASP.NET platformlarının hepsi Mysql , MSSQL 2008/ 2016 database sunucularıyla aynı anda kullanılabilmektedir. Bu paketler sunucu yükünün çok daha az olduğu , yüksek bant genişliğine sahip güçlü sunucular üzerinde barındırılmaktadır.</p> \
                                    <h3>Reseller (Bayi) Hosting Seçenekleri</h3> \
                                    <p>Reseller (Bayi) hesapları , birden fazla alan adı barındıran kişi yada kurumlar için özel olarak hazırlanmıştır. Bayilerimize özel kontrol panel yardımı ile ihtiyacınız olan hosting hesabını istediğiniz zaman istediğiniz yerden açıp , kontrol edebilirsiniz.</p> \
                                    <h3>Radyo(Stream) Hosting Seçenekleri</h3> \
                                    <p>Sitenizden online olarak radyo yada televizyon yayını yapmanızı sağlayan hosting hizmetimizdir. Sadece radyo olarak değil , herhangi konferans yada çoklu görüşme sunumları içinde tercih edilebilmektedir.</p> \
                                    <h3>Profesyonel E-mail Hosting Seçenekleri</h3> \
                                    <p>Sadece mail hizmeti vermek için ayarlanmış sunucularımızdan profesyonel olarak mail hosting hizmeti alabilirsiniz. Özelleşmiş ayarlamaları , kullanıcı ve kişi kontrollü , spam ve virüs koruma sistemleri her mail hesabı için tanımlanmıştır.</p> \
                                    <h3>Kampanya Paketlerimiz</h3> \
                                    <p>Her ay düzenli olarak oluşturulan kampanya paketlerimiz , tüm müşterlerimizin ihtiyaçlarına yönelik hazırlanmaktadır. Özel kampanyalarımızı kaçırmayın.</p> \
                                    <h3>SEO Hosting Reseller Paketleri</h3> \
                                    <p>Seo hosting paketleri , farklı Class IP adresleri üzerinden çalışmanızı sağlayan , özelleştirilmiş PHP ve ASP nin aynı sunucuda kullanılabildiği özel paketledir. Bu paketlerde CPU ve RAM kullanımı diğer paketlere göre daha yüksektir.</p>' 
                },
                
            ],
            supporters: [
                {
                    id: 1,
                    img: 'avatar-s-1.png',
                    name: 'Asım BERBER',
                    profession: 'Genel'
                },
                {
                    id: 2,
                    img: 'avatar-s-3.png',
                    name: 'Recep ATABAY',
                    profession: 'Satış Pazarlama'
                },
                {
                    id: 3,
                    img: 'avatar-s-21.png',
                    name: 'Musa KARAARSLAN',
                    profession: 'Eğitim Destek'
                },
                {
                    id: 4,
                    img: 'avatar-s-15.png',
                    name: 'Arif BERBER',
                    profession: 'Teknik Destek'
                },
                {
                    id: 5,
                    img: 'avatar-s-23.png',
                    name: 'Kadir ÇAKIR',
                    profession: 'Yazılım Geliştirme'
                },
            ]
        }
    },
    computed: {
        filteredFaq() { 
            return this.faqs.filter((faq) => {
                if (this.faqFilter == 1) return (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 2) return faq.categoryId == 2 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 3) return faq.categoryId == 3 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 4) return faq.categoryId == 4 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 5) return faq.categoryId == 5 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 6) return faq.categoryId == 6 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 7) return faq.categoryId == 7 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 8) return faq.categoryId == 8 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 9) return faq.categoryId == 9 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
            });
        }
    },
    methods: {
    },
    components: {
    }
}
</script>

<style lang="scss">
#faq-page {
    .faq-jumbotron-content {
        background-image: url('../../assets/images/pages/faq.jpg');
        background-size: cover;
    }

    .faq-bg {
        background-color: #fff;
    }   
}
</style>